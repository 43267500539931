.hero {
  width: 100%;
  position: relative;
  text-align: center;
}
.hero-slide3 {
  justify-content: center;
  align-items: center;
  display: flex;
  color:antiquewhite;
  height:75vh;
  background: linear-gradient(rgba(32, 28, 59, 0.7), rgba(39, 35, 66, 0.7)), url('../../Assets/hero22.jpg');
  padding-top: 210px;
  font-size: x-large;
  background-size: cover;
}
.hero-slide1 {
  justify-content: center;
  align-items: center;
  display: flex;
  color:antiquewhite;
  height:75vh;
  background: linear-gradient(rgba(32, 28, 59, 0.7), rgba(39, 35, 66, 0.7)), url('../../Assets/CyberAboutc.png');
  padding-top: 210px;
  font-size: x-large;
  background-size: cover;
}
.hero-slide2 {
  justify-content: center;
  align-items: center;
  display: flex;
  color:antiquewhite;
  height:75vh;
  background: linear-gradient(rgba(32, 28, 59, 0.7), rgba(39, 35, 66, 0.7)), url('../../Assets/CyberAboutc.png');
  padding-top: 210px;
  font-size: x-large;
  background-size: cover;
}

.slick-prev, .slick-next {
  z-index: 1;
  border-radius: 50%;
  padding: 20px;
}
.slick-prev{
 left: 20px;
}
.slick-next{
  right: 20px;
} 
.slick-prev::before, .slick-next::before{
  color:orange;
  font-size: 30px;
}
@media screen and (max-width: 768px){
  .hero{
     width: 100%;
     flex-wrap: wrap;
   }
  .hero-slide{
   width: 100%;
   display: inline;
   flex-wrap: wrap;
   margin-bottom: 100px;
   text-align: center; 
  }
  .hero-slide1 h2{
  text-align: center;
  }
  .hero-slide2 h2{
    text-align: center; 
  }
    .hero-slide3 h2{
    text-align:center;
   
   }
  
  }




