.blogs{
    width: 100%;
    margin-top: 0px;
    text-align: center;
    align-items: center;
    align-content: space-between;
    margin-bottom: 30px;
    display: flex;
    background: gainsboro;

}
.blogs-content{
    width: 30%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 40px;
    align-content: space-between;
    background:whitesmoke;
  
    
}
.blogs-title{
    width: 100%;
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    text-align: center;
    align-items: center;
    background: #545a93;
}
.blogs-title h1{
    text-align: justify;
    color: white;
    font-size: x-large;
    text-align: center;
       
}
.blogs-desc p{
    padding-left: 30px;
    padding-right: 30px;
    font-size: large;
    text-align: justify;
    font-family:'Times New Roman', Times, serif;
        
}
.blogs-image{
    border-radius:50%;
    margin-right: 60%;
    margin-left: -20%;
    background: #545a93;

}
.blogs-desc{
    width: 100%;
    padding-top: 5px;
    margin-bottom: 0px;
    margin-left: 0px;
    align-content: space-between; 

}

  @media (max-width: 760px){
    .blogs{
        display: block;
        width: 100%;
        margin: 0px 0px;
    }
    .blogs-content {
    width: 100%;
    flex-direction: column;
    margin: 20px 0px;
      
     }.blogs-desc {
      width: 100%;
      flex-direction: column;
     }
     .blogs-image {
        width: 100%;
     flex-direction: column;
     }
    .blogs-title{
     width: 100%;
     flex-direction: column;
        
    }
  }