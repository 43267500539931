.proj{
    width: 80%;
    color: black;
    font-size: xx-large;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 200px;
    padding-bottom: 200px;
}
.proj p{
  text-align: justify;
  font-size: large;
}
.proj h1{
  color: orange;
  font-size: xx-large;
}
@media screen and (max-width: 800px){
  .proj{
    width: 100%;
    padding-left: 3%;
    text-align: center;
    font-size: large;
  }
  .lognib{
    width: 100%;
  
  }

}