.principle{
    width: 100%;
    position: relative;
    background:#f1f1f1;
    
    
}
.principle1{
    background:#f1f1f1;
    border-style:solid;
    border-color:green;
    box-sizing:border-box;
    width: 300px;
    height: 100px;
}
.principle2{
    background:#f1f1f1;
    border-style:solid;
    border-color:yellow;
    box-sizing:border-box;
    width: 30px;
    height: 100px;
}

.principle3{
    background:#f1f1f1;
    border-style:solid;
    border-color:red;
    box-sizing:border-box;
    width: 30px;
    height: 100px;
}
        

.principle1 h1{
    text-align: center;
    text-align: justify;
    color:darkblue;
    font-size: large;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    
}
.principle2 h1{
    text-align: center;
    text-align: justify;
    color:darkblue;
    font-size: large;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
}
.principle3 h1{
    text-align: center;
    text-align: justify;
    color:darkblue;
    font-size: large;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    
}

@media (max-width: 850px)
{
 .principles{
   display:none;
    
 }   
 .principle1 h1{
    flex-wrap: wrap;
    font-size: small;
    padding-top: 40%;
    text-align: center;
    padding-top: 20%;
 }
 .principle1{
    height:148px;
    border-top-left-radius: 30%;
    border-top-right-radius: 30%;
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 30%;
    text-align: center;
    flex-wrap: wrap;
 }
 .principle2 h1{
    flex-wrap: wrap;
    font-size: small;
    text-align: center;
    padding-top: 20%;
 }
 .principle2{
    height:148px;
    border-top-left-radius: 30%;
    border-top-right-radius: 30%;
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 30%;
    flex-wrap: wrap;
 }
 .principle3{
    height:148px;
    border-top-left-radius: 30%;
    border-top-right-radius: 30%;
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 30%;
    flex-wrap: wrap;
   
 }
 .principle3 h1{
    flex-wrap: wrap;
    font-size: small;
    text-align: center;
    padding-top: 20%;
 }
}
