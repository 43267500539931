nav{
    width: 100%;
    color:white;
    padding: 4px 0;
    top: 0px;
    left: 0px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    
}
.nav-dark {
    background: #37517e;
    /*#545a93; fist color*/
    color:white;
    top: 0;
    left: 0;
    height: 70px;
    box-sizing: border-box;
    border-bottom-style:solid; 
    border-width: 0.15rem;
    border-color:orange;
    transition: all;
   
}
 .nav-dark a{
    color:white;
 }
.logo{
    width: 70px;
    height: 50px;
}
.logodiv{
    margin-top: 0%;
    margin-bottom: 0%;
    display: flex;
}
.logodiv h1{
 font-size:22px;
 margin: 10px 0px;
 padding-bottom: 0px;
 color: orange;
}
nav ul li{
display: inline-block;
list-style: none;
margin: 5px 20px;
font-size: 16px;  
}
.dropdown-menu {
  display: none;  /* Hidden by default */
  position: absolute;
  list-style-type: none;
  margin-left: -200px;
  padding:0;
  background-color: white;
  border: 1px solid #ddd;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  
  }
  .dropdown:hover .dropdown-menu {
    display: block;
    opacity: 1;
    z-index: 1;
      
  }
   .dropdown-link {
    padding: 12px 16px;
    border-bottom: 1px solid rgb(204, 196, 185);
    display: block;
    
  }
  .dropdown-link a{
    color: darkblue;
  }
   .dropdown-link:hover {
    background-color:darkorange;
  }
  .menutem{
    display: none;
}
@media screen and (max-width:800px) {
    .logo{
        width: 30px;
        height: 30px;
    }
    nav li ul{
        margin: 10px 15px;
    }
   }
   @media screen and (max-width:840px) {
    nav{
        padding: 15px 0;
    }
    .nav-dark{
        padding-right: 0%;
        height: 55px;
               
    }
    nav ul{
        position: fixed;
        top: 0%;
        right: 0%;
        bottom:auto;
        background: #545a93;
        z-index: -1;
        width: 175px;
        padding-top: 10px;
        transition: 0.5s;
    }
    nav ul li{
        display: block;
        margin: 25px 40px;
        padding-left: 0px;
    }
     .menutem{
        display:block;
        width: 25px;
        cursor:pointer;
        margin-right: 45px;       
     }
    .mobile-hid{
     right: -200px;
     padding-right: 5px; 
     width: 10px; 
           
    }
    .logodiv h1{
      font-size: small;
      text-wrap: wrap;
      padding-top: 25px;
    }
    .logo{
     width: 90px;
     height: 90px;
    }
    
     
}

 