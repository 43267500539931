.compliance{
    margin: 80px auto;
    max-width: 100%;
    display: flex;
    align-items: center;
    align-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    text-align: justify;
    font-size: large;
    background: linear-gradient(rgba(182, 177, 213, 0.7), rgba(192, 201, 205, 0.7))
}
.compliance-col{
    flex-basis: 50%;
       
}
.right-col h4{
    color: #357983;
    font-weight: 800;
    font-size: x-large;
    padding-left: 20px;
    
}
.right-col{
    flex-basis: 35%;
    
}
.right-col p{
    padding-left: 5%;
    padding-right: 50px;
    font-size: large;
    
  
}

.compliance-col h3{
    color: #357983;
    font-weight: 800;
    font-size: x-large;
    padding-top: 20px;
}
.compliance-col p{
    padding-left: 0%;
    padding-right: 30px;
    font-size: large;
}

.compliance-col span{
    display: block;
    margin: 20px 0;
}
.gaping2{
    padding-bottom: 30px;
    
}
@media screen and (max-width: 800px){
.compliance{
    width: 100;
    display: block;
}
.compliance-col h3{
    text-align: center;
}
.right-col{
    padding-top: 20px;
}

}
    

