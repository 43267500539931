.training{
    margin: 80px auto;
    max-width: 100%;
    display: flex;
    align-items: center;
    align-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    text-align: justify;
    font-size: large;
}
.training-col{
    flex-basis: 50%;
       
}
.training-col h3{
    color: #357983;
    font-weight: 800;
    font-size: x-large;
    padding-top: 20px;
}
.training-col p{
    padding-left: 0%;
    padding-right: 30px;
    font-size: large;
}

@media screen and (max-width: 800px)
{
    .training{
     width: 100;
     display: block;
    }
    .training-col h3{
      text-align: center;
    }
    .training-col p{
     text-align: justify;
     padding-left: 0%;
     padding-right: 0%;
    }
}
   