
.footer {
  background-color: #37517e;
  color: #fff;
  padding-top: 40px;
  margin-bottom:0;
  position:sticky;
  
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
}

.footer-column {
  flex: 1;
  min-width: 200px;
  margin: 10px;
  padding-right: 6%;
 
}
.footer-column p{
text-align: justify;
color: white

}
.footer-column h4 {
  margin-top: 0;
  color: orange;
}

.footer-column ul {
  list-style:armenian;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
  display: block;
}

.footer-column ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-column ul li a:hover {
  text-decoration: underline;
  color: orange;
}
.footer-copy{
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid whitesmoke;

}
@media screen and (max-width:800px)  {
  .footer-column, .footer-container, .footer-copy {
    width: 100%;

  }
  .footer-column p, h4, li{
    padding-left: 30px;
  }
}