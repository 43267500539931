.title{
    text-align: center;
    color: darkblue;
    font-size: 25px;
    font-weight: 600px;
    text-transform: uppercase;
    margin: 90px 0 80px;
}
.title h2 p{
    font-size: 50px;
    color: darkblue;
    margin-top: 5px;
    margin-bottom: 0;
    text-transform: none;

}