@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
*{
 margin: 0;
 padding: 0;
 box-sizing: border-box;
 font-family: "Outfit", sans-serif;
}
body{
  background: #f1f1f1;
  overflow-x: hidden;
}
a{
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}
.contain{
  padding-left: 0%;
  padding-right: 0%;
}
.btnprimery{
  margin: 420px auto;
  border-radius: 30px;
  background-color: darkblue;
  color: white;
  width: 100px ;
  font-size: x-large;
}

.btne{
  background-color: whitesmoke;
  color:darkblue;
  padding: 8px 15px;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
  border: 0;
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btne:hover{
  transform: translatex(10px);
  background-color: whitesmoke;
  color:darkblue ;
  border: 2px solid darkblue;
}
.btne img{
  width: 20px;
  margin-left: 10px;
}
@media screen and (max-width: 1200px){
  .contain{
    padding-left: 0%;
    padding-right: 0%;
  }
  .btne{
    padding: 14px 22px;
  }
}