.contact{
    margin: 80px auto;
    max-width: 75%;
    display: flex;
    align-items: center;
    align-content: space-between;
}
.contact-col{
    flex-basis: 48%;
    
}
.contact-col h3{
    color: #357983;
    font-weight: 500;
    font-size: large;
    padding-top: 20px;
}

.contact-col ul li{
    display: block;
}
.contact-col span{
    display: block;
    margin: 20px 0;
}
.social-con{
 display: flex;
 padding-left: 5px;
 align-content: space-between;   
}
.social-con a{
    color:#37517e;
    padding: 10px;
    font-size: large;
} 
@media screen and (max-width: 800px) {
    .contact, .contact-col{
    width: 100%;
    flex-wrap: wrap;
    }
}
