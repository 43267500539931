.about{
    
    margin: 30px auto;
    display: flex;
    align-items: center;
    align-content: space-between;
    width: 95%;
    text-align: justify;
    font-size: x-large;
    margin-bottom: 100px;
}
.about-left{
flex-basis: 60%;
}
.about-right p{
    font-family:'Times New Roman', Times, serif;
    padding-left: 20px;
    padding-right:20px;
    font-size: large;
    color: rgb(4, 81, 129);
   
}
.about-right{
   background: #f1f1f1;
}
.about-left p{
    padding-left: 20px;
}
.about-right h1{
 font-size: x-large;
 color: darkorange;
 font-style: normal;
 padding-left: 20px;
 padding-top: 30px;
 
}
.darkblu-text{
    color: darkblue;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.Our-values{
    width: 100%;
    background: #f1f1f1;
    text-align: justify;  
    margin-right: 40px;
    overflow: hidden; 
    
}
.Our-values h2{
    font-family:'Times New Roman', Times, serif;
    color: #357983;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: large;
}
.Our-values h3{
    color: darkorange;
    padding-left: 20px;
    font-size: x-large;
}
.gaping{
    padding-bottom: 30px;
    
}
@media screen and (max-width:800px)  {
  .about{
        display: block;
        width:100%;
    }
    .about-right{
        width: 100%;
        text-align: justify;
    }
    .about-right h1{
      text-align: center;
      
    }
    .Our-values h3{
        text-align: center;
        margin-top: 30px;
    
    }
  }