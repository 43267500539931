/* Card.css */
.programs{
  border: 1px dashed #ccc;
  height:auto;
  margin: 10px 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 30px;
  padding-right: 10px;
  padding-left: 10px;
  
 }
  .card{
    border: 1px solid white;
    width: 100%;
    flex-basis: 550px;
    background: white;
    text-align: justify;  
    margin-right: 20px;
    overflow: hidden;  
  }
  .card:hover{
    transform:translateX(10px);
       
  }
  .card-image{
    width: 100%;
    height: 200px;
    display: block;
    padding-top: 10px;
  }
  .card-content{
    background: white ;
    padding: 10px;
    width: 100%;
    text-align: justify;
    display: block;
  }
  .top-title{
    width: 100%;
    background: white;
    height: 60px;
    display: flex;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  .Maintitl{
    width: 100%;
    background: white;
    height: 40px;
 }
  .programs h1{
  margin:10px auto 20px;
  line-height: 1.4;
  color: orange;
  font-size: x-large;
 text-align: center;
  
 }
 .card-content{
  font-size: large;
  font-family: 'Courier New', Courier, monospace;
 }
 @media screen and (max-width:800px) {
    .programs{
        flex-direction:column;
        margin: 0px 0px;       
    }
     .card {
     flex-basis: 100%;
     margin: 0px 0px;
     margin-bottom: 20px;
    }
  }